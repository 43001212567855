var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showTimeZoneMsg,
                  expression: "showTimeZoneMsg"
                }
              ],
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg",
              attrs: { color: "#3F51B5", dark: "", width: "85%" }
            },
            [_vm._v(" Last question, which time zone are you in? ")]
          )
        ],
        1
      ),
      _vm.showTimeZoneMsg
        ? _c("div", [
            !_vm.timeZoneSubmitted
              ? _c("div", { staticClass: "d-flex justify-end" }, [
                  _c(
                    "form",
                    [
                      _c(
                        "v-list",
                        {
                          staticClass: "py-0",
                          style: {
                            width: "fit-content",
                            "border-radius": "1%",
                            border: "1px solid #00A9F4"
                          },
                          attrs: { dense: "" }
                        },
                        _vm._l(_vm.TIME_ZONE_CHOICES, function(c, i) {
                          return _c(
                            "v-list-item",
                            {
                              key: c.label,
                              staticClass: "my-0 py-0",
                              style: {
                                "border-bottom":
                                  i !== _vm.TIME_ZONE_CHOICES.length - 1
                                    ? "1px solid #00A9F4"
                                    : "",
                                "background-color": "white"
                              },
                              on: {
                                click: function($event) {
                                  _vm.user.timeZone = c.label
                                  _vm.timeZoneSubmitted = true
                                  _vm.submitTimezone()
                                }
                              }
                            },
                            [
                              _c("span", { style: { color: "#00A9F4" } }, [
                                _vm._v(" " + _vm._s(c.label) + " ")
                              ])
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ])
              : _c(
                  "div",
                  { staticClass: "d-flex justify-end my-5" },
                  [
                    _c(
                      "v-list",
                      {
                        staticClass:
                          "rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-0",
                        attrs: {
                          outlined: "",
                          rounded: "",
                          width: "fit-content",
                          dense: ""
                        }
                      },
                      [
                        _c("v-list-item", [
                          _vm._v(" " + _vm._s(_vm.user.timeZone) + " ")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }