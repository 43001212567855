<template>
  <div>
    <v-expand-transition>
      <v-alert
        color="#3F51B5"
        dark
        class="rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg"
        width="85%"
        v-show="showOrgNameMsg"
      >
        What is the name of the organization that will be using this account?
      </v-alert>
    </v-expand-transition>

    <div v-if="showOrgNameMsg">
      <div v-if="!userGroupSubmitted" class="my-5">
        <validation-observer ref="userGroupNameForm">
          <form @submit.prevent="submitUserGroupName">
            <validation-provider rules="required">
              <v-text-field
                outlined
                color="#E5E5E5"
                v-model.trim="userGroup.name"
                hide-details="auto"
                name="userGroup"
                single-line
                background-color="white"
                full-width
                ref="userGroupNameInput"
              >
                <template v-slot:append>
                  <div class="mt-n2">
                    <v-btn
                      icon
                      type="submit"
                      color="#3F51B5"
                      :disabled="!userGroup.name"
                    >
                      <v-icon>
                        {{ mdiSend }}
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-text-field>
            </validation-provider>
          </form>
        </validation-observer>
      </div>
      <div v-else class="d-flex justify-end my-5">
        <v-list
          outlined
          rounded
          width="fit-content"
          dense
          class="rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-0"
        >
          <v-list-item>
            {{ userGroup.name }}
          </v-list-item>
        </v-list>
      </div>
    </div>

    <RepeatUserGroupNameForm
      v-for="t of showRepeatUserGroupNameFormTimes"
      :key="t"
      :index="t"
      @step-2-finished="$emit('step-2-finished', $event)"
      @show-repeat-user-group-form-again="
        showRepeatUserGroupNameFormTimes++;
        prevEnteredGroupNames.push($event);
      "
      :prevEnteredGroupNames="prevEnteredGroupNames"
      :enteredUser="enteredUser"
    />
  </div>
</template>

<script>
import { mdiSend } from "@mdi/js";
import RepeatUserGroupNameForm from "@/components/signUp/signUpStep2/RepeatUserGroupNameForm";
import { axiosWithRegularAuth } from "@/plugins/axios";
import sleep from "@/mixins/sleep";
const APIURL = process.env.VUE_APP_API_URL;

const USER_GROUP_OPTIONS = {
  CREATE_NEW_USER: 1,
  CONTACT_UTILISYNC_SUPPORT: 2,
  ENTER_ORG_NAME_AGAIN: 3,
};

export default {
  name: "SignUpStep2",
  props: {
    enteredUser: Object,
  },
  components: {
    RepeatUserGroupNameForm,
  },
  data() {
    return {
      mdiSend,
      userGroup: {
        name: "",
      },
      USER_GROUP_OPTIONS,
      showRepeatUserGroupNameFormTimes: 0,
      userGroupOptionValue: undefined,
      userGroupSubmitted: false,
      prevEnteredGroupNames: [],
      showOrgNameMsg: false,
    };
  },
  methods: {
    async submitUserGroupName() {
      const success = await this.$refs.userGroupNameForm.validate();
      if (!success) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/user_groups_with_name/${this.userGroup.name}`
      );
      if (!results) {
        this.$emit("step-2-finished", this.userGroup);
      } else {
        this.showRepeatUserGroupNameFormTimes++;
        this.prevEnteredGroupNames.push(this.userGroup.name);
      }
      this.userGroupSubmitted = true;
    },
  },
  async mounted() {
    await sleep(1000);
    this.showOrgNameMsg = true;
    await this.$nextTick();
    if (this.$vuetify.breakpoint.smAndUp) {
      this.$refs.userGroupNameInput.$refs.input.focus();
    }
  },
};
</script>
