<template>
  <div>
    <v-expand-transition>
      <v-alert
        color="#3F51B5"
        dark
        class="rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg"
        width="85%"
        v-show="showWorkEmailMsg"
      >
        What is your work email?
      </v-alert>
    </v-expand-transition>

    <div v-if="showWorkEmailMsg">
      <div v-if="!emailSubmitted" class="my-5">
        <validation-observer ref="emailForm">
          <form @submit.prevent="submitEmail">
            <validation-provider :rules="{ email: true, required: true }">
              <v-text-field
                outlined
                color="#E5E5E5"
                v-model.trim="user.email"
                hide-details="auto"
                name="email"
                single-line
                background-color="white"
                full-width
                ref="emailInput"
              >
                <template v-slot:append>
                  <div class="mt-n2">
                    <v-btn
                      icon
                      type="submit"
                      color="#3F51B5"
                      :disabled="!user.email"
                    >
                      <v-icon>
                        {{ mdiSend }}
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-text-field>
            </validation-provider>
          </form>
        </validation-observer>
      </div>
      <div v-else class="d-flex justify-end my-5">
        <v-list
          outlined
          rounded
          width="fit-content"
          dense
          class="rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-0"
        >
          <v-list-item>
            {{ user.email }}
          </v-list-item>
        </v-list>
      </div>
    </div>

    <RepeatEmailForm
      v-for="t of showRepeatUserEmailFormTimes"
      :key="t"
      :index="t"
      :userGroup="userGroup"
      @step-3-finished="$emit('step-3-finished', $event)"
      @show-repeat-email-form-again="showRepeatUserEmailFormTimes++"
    />
  </div>
</template>

<script>
import { mdiSend } from "@mdi/js";
import RepeatEmailForm from "@/components/signUp/signUpStep3/RepeatEmailForm";
import { axiosWithRegularAuth } from "@/plugins/axios";
import sleep from "@/mixins/sleep";
const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SignUpStep3",
  components: {
    RepeatEmailForm,
  },
  props: {
    userGroup: Object,
  },
  data() {
    return {
      user: {
        email: "",
      },
      emailOptionValue: undefined,
      mdiSend,
      showRepeatUserEmailFormTimes: 0,
      emailSubmitted: false,
      showWorkEmailMsg: false,
    };
  },
  computed: {
    emailOption() {
      return this.EMAIL_OPTIONS.find((e) => e.value === this.emailOptionValue)
        ?.value;
    },
  },
  methods: {
    async submitEmail() {
      const success = await this.$refs.emailForm.validate();
      if (!success) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/users_by_email/${this.user.email}`
      );
      if (!results) {
        this.$emit("step-3-finished", this.user);
      } else {
        this.showRepeatUserEmailFormTimes++;
      }
      this.emailSubmitted = true;
    },
  },
  async mounted() {
    await sleep(1000);
    this.showWorkEmailMsg = true;
    await this.$nextTick();
    if (this.$vuetify.breakpoint.smAndUp) {
      this.$refs.emailInput.$refs.input.focus();
    }
  },
};
</script>
