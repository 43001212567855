<template>
  <div>
    <v-expand-transition>
      <v-alert
        color="#3F51B5"
        dark
        class="rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg"
        width="85%"
        v-show="showAlreadyExistMsg"
      >
        An account for {{ prevEnteredGroupName }} already exists. Are you trying
        to create a new user for that account?
      </v-alert>
    </v-expand-transition>

    <div class="d-flex justify-end mb-5" v-if="showAlreadyExistMsg">
      <v-list
        :style="{
          'border-radius': '1%',
          border: '1px solid #00A9F4',
        }"
        class="py-0"
        width="fit-content"
        dense
        v-if="!userGroupOptionValue"
      >
        <v-list-item
          :style="{
            'border-bottom': '1px solid #00A9F4',
            'background-color':
              userGroupOptionValue === USER_GROUP_OPTIONS.CREATE_NEW_USER
                ? '#00A9F4'
                : 'white',
          }"
          color="#3F51B5"
          class="my-0 py-0"
          @click="userGroupOptionValue = USER_GROUP_OPTIONS.CREATE_NEW_USER"
        >
          <span
            :style="{
              color:
                userGroupOptionValue === USER_GROUP_OPTIONS.CREATE_NEW_USER
                  ? 'white'
                  : '#00A9F4',
            }"
          >
            I want to create a new user for {{ prevEnteredGroupName }}
          </span>
        </v-list-item>
        <v-list-item
          :style="{
            'border-bottom': '1px solid #00A9F4',
            'background-color':
              userGroupOptionValue ===
              USER_GROUP_OPTIONS.CONTACT_UTILISYNC_SUPPORT
                ? '#00A9F4'
                : 'white',
          }"
          color="#3F51B5"
          class="my-0 py-0"
          @click="
            userGroupOptionValue = USER_GROUP_OPTIONS.CONTACT_UTILISYNC_SUPPORT
          "
        >
          <span
            :style="{
              color:
                userGroupOptionValue ===
                USER_GROUP_OPTIONS.CONTACT_UTILISYNC_SUPPORT
                  ? 'white'
                  : '#00A9F4',
            }"
          >
            I want to contact UtiliSync811 Support.
          </span>
        </v-list-item>
        <v-list-item
          :style="{
            'background-color':
              userGroupOptionValue === USER_GROUP_OPTIONS.ENTER_ORG_NAME_AGAIN
                ? '#00A9F4'
                : 'white',
          }"
          color="#3F51B5"
          class="my-0 py-0"
          @click="
            userGroupOptionValue = USER_GROUP_OPTIONS.ENTER_ORG_NAME_AGAIN
          "
        >
          <span
            :style="{
              color:
                userGroupOptionValue === USER_GROUP_OPTIONS.ENTER_ORG_NAME_AGAIN
                  ? 'white'
                  : '#00A9F4',
            }"
          >
            I would like to enter my organization name again.
          </span>
        </v-list-item>
      </v-list>
      <v-list
        v-else
        outlined
        rounded
        width="fit-content"
        class="rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-0"
      >
        <v-list-item>
          {{ userGroupOption }}
        </v-list-item>
      </v-list>
    </div>

    <v-expand-transition>
      <v-alert
        color="#3F51B5"
        dark
        class="rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg"
        width="85%"
        v-show="userGroupOptionValue === USER_GROUP_OPTIONS.CREATE_NEW_USER"
      >
        Great. I’m only programmed to create new accounts. Please contact your
        UtiliSync811 admin or contact UtiliSync support by
        <a href="#" @click="showChat()" class="white--text">clicking here</a>
        and they can help you with that.
      </v-alert>
    </v-expand-transition>

    <v-expand-transition>
      <v-alert
        color="#3F51B5"
        dark
        class="rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg"
        width="85%"
        v-show="
          userGroupOptionValue === USER_GROUP_OPTIONS.CONTACT_UTILISYNC_SUPPORT
        "
      >
        Great.
        <a href="#" @click="showChat()" class="white--text">Click here</a> to
        send an email to support. They typically respond within a few hours.
      </v-alert>
    </v-expand-transition>

    <v-expand-transition>
      <v-alert
        color="#3F51B5"
        dark
        class="rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg"
        width="85%"
        v-show="
          userGroupOptionValue === USER_GROUP_OPTIONS.ENTER_ORG_NAME_AGAIN
        "
      >
        What is the name of the organization that will be using this account?
      </v-alert>
    </v-expand-transition>

    <div
      v-if="userGroupOptionValue === USER_GROUP_OPTIONS.ENTER_ORG_NAME_AGAIN"
    >
      <div v-if="!userGroupSubmitted" class="my-5">
        <validation-observer>
          <form @submit.prevent="submitUserGroupName">
            <validation-provider>
              <v-text-field
                outlined
                color="#E5E5E5"
                v-model.trim="userGroup.name"
                hide-details="auto"
                name="name"
                single-line
                background-color="white"
                full-width
                ref="userGroupNameInput"
              >
                <template v-slot:append>
                  <div class="mt-n2">
                    <v-btn
                      icon
                      type="submit"
                      color="#3F51B5"
                      :disabled="!userGroup.name"
                    >
                      <v-icon>
                        {{ mdiSend }}
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-text-field>
            </validation-provider>
          </form>
        </validation-observer>
      </div>
      <div v-else class="d-flex justify-end my-5">
        <v-list
          outlined
          rounded
          width="fit-content"
          dense
          class="rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-0"
        >
          <v-list-item>
            {{ userGroup.name }}
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiSend } from "@mdi/js";
import { axiosWithRegularAuth } from "@/plugins/axios";
import sleep from "@/mixins/sleep";

const APIURL = process.env.VUE_APP_API_URL;
const USER_GROUP_OPTIONS = {
  CREATE_NEW_USER: 1,
  CONTACT_UTILISYNC_SUPPORT: 2,
  ENTER_ORG_NAME_AGAIN: 3,
};
export default {
  name: "RepeatUserGroupNameForm",
  props: {
    prevEnteredGroupNames: Array,
    index: Number,
    enteredUser: Object,
  },
  data() {
    return {
      mdiSend,
      userGroup: {
        name: "",
      },
      userGroupOptionValue: undefined,
      USER_GROUP_OPTIONS,
      userGroupSubmitted: false,
      showAlreadyExistMsg: false,
    };
  },
  watch: {
    async userGroupOptionValue(val) {
      if (val === USER_GROUP_OPTIONS.ENTER_ORG_NAME_AGAIN) {
        await this.$nextTick();
        if (this.$vuetify.breakpoint.smAndUp) {
          this.$refs.userGroupNameInput.$refs.input.focus();
        }
      }
    },
  },
  computed: {
    prevEnteredGroupName() {
      return this.prevEnteredGroupNames[this.index - 1];
    },
    userGroupOption() {
      if (this.userGroupOptionValue === USER_GROUP_OPTIONS.CREATE_NEW_USER) {
        return `I want to create a new user for ${this.prevEnteredGroupName}`;
      } else if (
        this.userGroupOptionValue ===
        USER_GROUP_OPTIONS.CONTACT_UTILISYNC_SUPPORT
      ) {
        return "I want to contact UtiliSync811 Support.";
      } else {
        return "I would like to enter my organization name again.";
      }
    },
  },
  methods: {
    async submitUserGroupName() {
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/user_groups_with_name/${this.userGroup.name}`
      );
      if (!results) {
        this.$emit("step-2-finished", this.userGroup);
      } else {
        this.$emit("show-repeat-user-group-form-again", this.userGroup.name);
      }
      this.userGroupSubmitted = true;
    },
    showChat() {
      const { firstName, lastName } = this.enteredUser ?? {};
      const subject = "Help creating UtiliSync811 Account";
      const text = `I am trying to use the Create Account UtiliBot to create a new account for ${this.prevEnteredGroupName}, but it says that account already exists. Can you help me sort this out?`;
      window.Beacon("config", {
        mode: "askFirst",
      });
      window.Beacon("prefill", {
        name: `${firstName} ${lastName}`,
        subject,
        text,
      });
      window.Beacon("event", {
        type: "page-viewed",
        url: document.location.href,
        title: document.title,
      });
      window.Beacon("suggest");
      window.Beacon("open");
    },
  },
  async mounted() {
    await sleep(1000);
    this.showAlreadyExistMsg = true;
  },
};
</script>
