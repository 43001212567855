var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          staticClass: "elevation-2 mb-3",
          attrs: { fab: "", color: "#3F51B5", small: "" }
        },
        [
          _c("img", {
            staticStyle: { width: "35px", height: "35px" },
            attrs: { src: require("@/assets/UtiliBotIconWhiteV2.svg") }
          })
        ]
      ),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showWelcomeMsg,
                  expression: "showWelcomeMsg"
                }
              ],
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg",
              attrs: { color: "#3F51B5", dark: "", width: "85%" }
            },
            [
              _vm._v(
                " Hi! I’m the Create Account UtiliBot and I will guide you through the process of creating a UtiliSync account. Let’s begin. "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFirstNameMsg,
                  expression: "showFirstNameMsg"
                }
              ],
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg",
              attrs: { color: "#3F51B5", dark: "", width: "85%" }
            },
            [_vm._v(" What is your first name? ")]
          )
        ],
        1
      ),
      _vm.showFirstNameMsg
        ? _c("div", [
            !_vm.submittedFirstName
              ? _c(
                  "div",
                  { staticClass: "my-5" },
                  [
                    _c("validation-observer", [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.saveFirstName.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c(
                            "validation-provider",
                            { attrs: { rules: "required" } },
                            [
                              _c("v-text-field", {
                                ref: "firstNameInput",
                                attrs: {
                                  outlined: "",
                                  color: "#E5E5E5",
                                  "hide-details": "auto",
                                  name: "firstName",
                                  "single-line": "",
                                  "background-color": "white",
                                  "full-width": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "mt-n2" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    type: "submit",
                                                    color: "#3F51B5",
                                                    disabled: !_vm.user
                                                      .firstName
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.mdiSend) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  2745957957
                                ),
                                model: {
                                  value: _vm.user.firstName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.user,
                                      "firstName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "user.firstName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "d-flex justify-end my-5" },
                  [
                    _c(
                      "v-list",
                      {
                        staticClass:
                          "rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-0",
                        attrs: {
                          outlined: "",
                          rounded: "",
                          width: "fit-content",
                          dense: ""
                        }
                      },
                      [
                        _c("v-list-item", [
                          _vm._v(" " + _vm._s(_vm.user.firstName) + " ")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
          ])
        : _vm._e(),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.submittedFirstName,
                  expression: "submittedFirstName"
                }
              ],
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg",
              attrs: { color: "#3F51B5", dark: "", width: "85%" }
            },
            [
              _vm._v(
                " Hi " + _vm._s(_vm.user.firstName) + ". Nice to meet you! "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showLastNameMsg,
                  expression: "showLastNameMsg"
                }
              ],
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg",
              attrs: { color: "#3F51B5", dark: "", width: "85%" }
            },
            [_vm._v(" What is your last name? ")]
          )
        ],
        1
      ),
      _vm.showLastNameMsg
        ? _c("div", { staticClass: "my-5" }, [
            !_vm.submittedLastName
              ? _c(
                  "div",
                  [
                    _c("validation-observer", [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.saveLastName.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c(
                            "validation-provider",
                            { attrs: { rules: "required" } },
                            [
                              _c("v-text-field", {
                                ref: "lastNameInput",
                                attrs: {
                                  outlined: "",
                                  color: "#E5E5E5",
                                  "hide-details": "auto",
                                  name: "lastName",
                                  "single-line": "",
                                  "background-color": "white",
                                  "full-width": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "mt-n2" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    type: "submit",
                                                    color: "#3F51B5",
                                                    disabled: !_vm.user.lastName
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.mdiSend) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  1760054997
                                ),
                                model: {
                                  value: _vm.user.lastName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.user,
                                      "lastName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "user.lastName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "d-flex justify-end" },
                  [
                    _c(
                      "v-list",
                      {
                        staticClass:
                          "rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-0",
                        attrs: {
                          outlined: "",
                          rounded: "",
                          width: "fit-content",
                          dense: ""
                        }
                      },
                      [
                        _c("v-list-item", [
                          _vm._v(" " + _vm._s(_vm.user.lastName) + " ")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }