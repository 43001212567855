<template>
  <div>
    <v-expand-transition>
      <v-alert
        color="#3F51B5"
        dark
        class="rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg"
        width="85%"
        v-show="showUsernameMsg"
      >
        Sorry, that username is already taken. Please enter another username.
      </v-alert>
    </v-expand-transition>

    <div v-if="showUsernameMsg">
      <div v-if="!usernameSubmitted" class="my-5">
        <validation-observer ref="usernameForm">
          <form @submit.prevent="submitUsername">
            <validation-provider
              :rules="{ required: true, regex: /^[a-z0-9_-]{6,}$/ }"
            >
              <v-text-field
                outlined
                color="#E5E5E5"
                v-model="user.username"
                hide-details="auto"
                name="name"
                single-line
                background-color="white"
                full-width
                @input="validateUsername"
                ref="usernameInput"
              >
                <template v-slot:append>
                  <div class="mt-n2">
                    <v-btn
                      icon
                      type="submit"
                      color="#3F51B5"
                      :disabled="!usernameValid"
                    >
                      <v-icon>
                        {{ mdiSend }}
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-text-field>
            </validation-provider>
          </form>
        </validation-observer>
      </div>
      <div v-else class="d-flex justify-end my-5">
        <v-list
          outlined
          rounded
          width="fit-content"
          dense
          class="rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-0"
        >
          <v-list-item>
            {{ user.username }}
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiSend } from "@mdi/js";
import { axiosWithRegularAuth } from "@/plugins/axios";
import sleep from "@/mixins/sleep";
const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "RepeatUserNameForm",
  data() {
    return {
      mdiSend,
      user: {
        username: "",
      },
      usernameSubmitted: false,
      showRepeatUsernameFormTimes: 0,
      showUsernameMsg: false,
      usernameValid: false,
    };
  },
  async mounted() {
    await sleep(1000);
    this.showUsernameMsg = true;
    await this.$nextTick();
    if (this.$vuetify.breakpoint.smAndUp) {
      this.$refs.usernameInput.$refs.input.focus();
    }
  },
  methods: {
    async validateUsername() {
      await this.$nextTick();
      const success = await this.$refs.usernameForm.validate();
      this.usernameValid = success;
    },
    async submitUsername() {
      const success = await this.$refs.usernameForm.validate();
      if (!success) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/users_by_username/${this.user.username}`
      );
      if (!results) {
        this.$emit("step-4-finished", this.user);
      } else {
        this.$emit("show-repeat-username-form-again");
      }
      this.usernameSubmitted = true;
    },
  },
};
</script>
