var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showWorkEmailMsg,
                  expression: "showWorkEmailMsg"
                }
              ],
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg",
              attrs: { color: "#3F51B5", dark: "", width: "85%" }
            },
            [_vm._v(" What is your work email? ")]
          )
        ],
        1
      ),
      _vm.showWorkEmailMsg
        ? _c("div", [
            !_vm.emailSubmitted
              ? _c(
                  "div",
                  { staticClass: "my-5" },
                  [
                    _c("validation-observer", { ref: "emailForm" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.submitEmail.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c(
                            "validation-provider",
                            {
                              attrs: { rules: { email: true, required: true } }
                            },
                            [
                              _c("v-text-field", {
                                ref: "emailInput",
                                attrs: {
                                  outlined: "",
                                  color: "#E5E5E5",
                                  "hide-details": "auto",
                                  name: "email",
                                  "single-line": "",
                                  "background-color": "white",
                                  "full-width": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "mt-n2" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    type: "submit",
                                                    color: "#3F51B5",
                                                    disabled: !_vm.user.email
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.mdiSend) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3570034900
                                ),
                                model: {
                                  value: _vm.user.email,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.user,
                                      "email",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "user.email"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "d-flex justify-end my-5" },
                  [
                    _c(
                      "v-list",
                      {
                        staticClass:
                          "rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-0",
                        attrs: {
                          outlined: "",
                          rounded: "",
                          width: "fit-content",
                          dense: ""
                        }
                      },
                      [
                        _c("v-list-item", [
                          _vm._v(" " + _vm._s(_vm.user.email) + " ")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
          ])
        : _vm._e(),
      _vm._l(_vm.showRepeatUserEmailFormTimes, function(t) {
        return _c("RepeatEmailForm", {
          key: t,
          attrs: { index: t, userGroup: _vm.userGroup },
          on: {
            "step-3-finished": function($event) {
              return _vm.$emit("step-3-finished", $event)
            },
            "show-repeat-email-form-again": function($event) {
              _vm.showRepeatUserEmailFormTimes++
            }
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }