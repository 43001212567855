<template>
  <div>
    <v-expand-transition>
      <v-alert
        color="#3F51B5"
        dark
        class="rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg"
        width="85%"
        v-show="showEmailMsg"
      >
        It looks like you already have an account on UtiliSync811. Would you
        like me to send you an email with your username and link to reset your
        password?
      </v-alert>
    </v-expand-transition>

    <div class="d-flex justify-end mb-5" v-if="showEmailMsg">
      <v-list
        :style="{
          'border-radius': '1%',
          border: '1px solid #00A9F4',
        }"
        class="py-0"
        dense
        width="fit-content"
        v-if="!emailOption"
      >
        <v-list-item
          v-for="(e, i) of EMAIL_USER_OPTIONS"
          :key="e.value"
          :style="{
            'border-bottom':
              i !== EMAIL_USER_OPTIONS.length - 1 ? '1px solid #00A9F4' : '',
            'background-color':
              emailOptionValue === e.value ? '#00A9F4' : 'white',
          }"
          color="#3F51B5"
          class="my-0 py-0"
          @click="onEmailUserOptionClick(e.value)"
        >
          <span
            :style="{
              color: emailOptionValue === e.value ? 'white' : '#00A9F4',
            }"
          >
            {{ e.label }}
          </span>
        </v-list-item>
      </v-list>
      <v-list v-else outlined rounded width="fit-content">
        <v-list-item>
          {{ emailOption }}
        </v-list-item>
      </v-list>
    </div>

    <v-expand-transition>
      <v-alert
        color="#3F51B5"
        dark
        class="rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg"
        width="85%"
        v-show="emailOptionValue === EMAIL_OPTIONS.ENTER_EMAIL_AGAIN"
      >
        What is your work email?
      </v-alert>
    </v-expand-transition>

    <div v-if="emailOptionValue === EMAIL_OPTIONS.ENTER_EMAIL_AGAIN">
      <div v-if="!emailSubmitted" class="my-5">
        <validation-observer ref="emailForm">
          <form @submit.prevent="submitEmail">
            <validation-provider rules="required">
              <v-text-field
                outlined
                color="#E5E5E5"
                v-model.trim="user.email"
                hide-details="auto"
                name="email"
                single-line
                background-color="white"
                full-width
                ref="emailInput"
              >
                <template v-slot:append>
                  <div class="mt-n2">
                    <v-btn
                      icon
                      type="submit"
                      color="#3F51B5"
                      :disabled="!user.email"
                    >
                      <v-icon>
                        {{ mdiSend }}
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-text-field>
            </validation-provider>
          </form>
        </validation-observer>
      </div>
      <div v-else class="d-flex justify-end my-5">
        <v-list
          outlined
          rounded
          width="fit-content"
          dense
          class="rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-0"
        >
          <v-list-item>
            {{ user.email }}
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiSend } from "@mdi/js";
import { axiosWithRegularAuth } from "@/plugins/axios";
import sleep from "@/mixins/sleep";

const APIURL = process.env.VUE_APP_API_URL;
const EMAIL_OPTIONS = {
  SEND_TO_SIGNIN_PAGE: 1,
  ENTER_EMAIL_AGAIN: 2,
};

const EMAIL_USER_OPTIONS = [
  {
    label: "Yes, send me to the signin page.",
    value: EMAIL_OPTIONS.SEND_TO_SIGNIN_PAGE,
  },
  {
    label: "I would like to enter my email again.",
    value: EMAIL_OPTIONS.ENTER_EMAIL_AGAIN,
  },
];

export default {
  name: "RepeatEmailForm",
  props: {
    index: Number,
    userGroup: Object,
  },
  watch: {
    async emailOptionValue(val) {
      if (val === EMAIL_OPTIONS.ENTER_EMAIL_AGAIN) {
        await this.$nextTick();
        if (this.$vuetify.breakpoint.smAndUp) {
          this.$refs.emailInput.$refs.input.focus();
        }
      }
    },
  },
  data() {
    return {
      EMAIL_OPTIONS,
      EMAIL_USER_OPTIONS,
      user: {
        email: "",
      },
      emailOptionValue: undefined,
      mdiSend,
      emailSubmitted: false,
      showEmailMsg: false,
    };
  },
  async mounted() {
    await sleep(1000);
    this.showEmailMsg = true;
  },
  computed: {
    emailOption() {
      return this.EMAIL_USER_OPTIONS.find(
        (e) => e.value === this.emailOptionValue
      )?.label;
    },
  },
  methods: {
    async submitEmail() {
      const success = await this.$refs.emailForm.validate();
      if (!success) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithRegularAuth.get(
        `${APIURL}/users_by_email/${this.user.email}`
      );
      if (!results) {
        this.$emit("step-3-finished", this.user);
      } else {
        this.$emit("show-repeat-email-form-again", this.user.email);
      }
      this.emailSubmitted = true;
    },
    onEmailUserOptionClick(value) {
      this.emailOptionValue = value;
      if (value === EMAIL_OPTIONS.SEND_TO_SIGNIN_PAGE) {
        this.$router.push("/login");
      }
    },
  },
};
</script>
