<template>
  <v-app>
    <v-row class="mx-0 px-0">
      <v-col
        cols="12"
        sm="6"
        class="d-flex py-10 px-16"
        :class="{
          'justify-end': $vuetify.breakpoint.smAndUp,
          'justify-center': $vuetify.breakpoint.xsOnly,
        }"
        style="background-color: #00a9f4"
      >
        <div style="max-width: 400px">
          <div
            class="d-flex justify-center"
            :class="{ 'my-8': $vuetify.breakpoint.xsOnly }"
          >
            <img
              :src="require('@/assets/UtiliSyncFieldReportsWhite.svg')"
              style="width: 100%"
            />
          </div>
          <p class="white--text" style="margin-top: 16px; font-size: 24px">
            Create field reports... from the field!
          </p>
          <p class="white--text">
            Stop wasting precious office time filling out Word documents,
            downloading photos and creating PDF reports. You can do all that and
            more with UtiliSync Docs.
          </p>
          <p class="white--text">
            So what are you waiting for? Sign up for your free UtiliSync account
            today.
          </p>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="d-flex"
        :style="{
          height: '100vh',
          'overflow-y': 'auto',
        }"
        :class="{
          'px-16': $vuetify.breakpoint.mdAndUp,
          'px-8': $vuetify.breakpoint.smAndDown,
        }"
        ref="signUpBox"
      >
        <v-card class="elevation-0" max-width="400px">
          <v-card-text>
            <div ref="signUpInnerBox">
              <SignUpStep1
                @step-1-submit="onStep1Submit"
                @step-1-finished="step1Finished = true"
              />
              <SignUpStep2
                :enteredUser="user"
                @step-2-finished="onStep2Finished"
                v-if="step1Finished"
              />
              <SignUpStep3
                @step-3-finished="onStep3Finished"
                v-if="step1Finished && step2Finished"
                :userGroup="userGroup"
              />
              <SignUpStep4
                @step-4-finished="onStep4Finished"
                v-if="step1Finished && step2Finished && step3Finished"
              />
              <SignUpStep5
                @step-5-finished="onStep5Finished"
                v-if="
                  step1Finished &&
                  step2Finished &&
                  step3Finished &&
                  step4Finished
                "
              />

              <v-expand-transition>
                <v-alert
                  color="#3F51B5"
                  dark
                  class="rounded-tl-lg rounded-tr-lg rounded-br-lg"
                  width="85%"
                  v-show="step5Finished"
                >
                  Perfect! I have everything I need to create your account. Just
                  give me a minute to get everything created.
                </v-alert>
              </v-expand-transition>

              <v-expand-transition>
                <v-alert
                  color="#3F51B5"
                  dark
                  class="rounded-tl-lg rounded-tr-lg rounded-br-lg"
                  width="85%"
                  v-show="step5Finished"
                >
                  All set! Your new account is created and is waiting for you. I
                  just sent an email to {{ user.email }} with a link to create
                  your password. Watch for that to come in the next few minutes.
                  Thanks for coming by today. It’s been a pleasure working with
                  you!
                </v-alert>
              </v-expand-transition>

              <v-expand-transition>
                <v-btn text to="/login" v-show="step5Finished">Sign In</v-btn>
              </v-expand-transition>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import SignUpStep1 from "@/components/signUp/SignUpStep1";
import SignUpStep2 from "@/components/signUp/SignUpStep2";
import SignUpStep3 from "@/components/signUp/SignUpStep3";
import SignUpStep4 from "@/components/signUp/SignUpStep4";
import SignUpStep5 from "@/components/signUp/SignUpStep5";
import { axiosWithRegularAuth } from "@/plugins/axios";
import moment from "moment";
const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SignUp",
  components: {
    SignUpStep1,
    SignUpStep2,
    SignUpStep3,
    SignUpStep4,
    SignUpStep5,
  },
  data() {
    return {
      user: {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        timeZone: "",
      },
      userGroup: {
        name: "",
      },
      step1Finished: false,
      step2Finished: false,
      step3Finished: false,
      step4Finished: false,
      step5Finished: false,
    };
  },
  methods: {
    onStep1Submit({ firstName, lastName }) {
      this.user = {
        ...this.user,
        firstName,
        lastName,
      };
    },
    onStep2Finished(userGroup) {
      this.userGroup = {
        ...this.userGroup,
        ...userGroup,
      };
      this.step2Finished = true;
    },
    onStep3Finished({ email }) {
      this.user = {
        ...this.user,
        email,
      };
      this.step3Finished = true;
    },
    async onStep4Finished({ username }) {
      this.user = {
        ...this.user,
        username,
      };
      this.step4Finished = true;
    },
    async onStep5Finished({ timeZone }) {
      this.user = {
        ...this.user,
        timeZone,
      };
      await this.signUpUserGroup();
    },
    async signUpUserGroup() {
      const { username, email, firstName, lastName, timeZone } = this.user;
      const { name } = this.userGroup;
      await axiosWithRegularAuth.post(`${APIURL}/user_groups/sign_up`, {
        username,
        email,
        user_group_name: name,
        group_plan: 0,
        f_name: firstName,
        l_name: lastName,
        time_zone: timeZone,
        plan_signup: moment(),
        app: "inspect",
      });
      this.step5Finished = true;
    },
    async scrollToBottom() {
      await this.$nextTick();
      if (this.$vuetify.breakpoint.xsOnly) {
        window.scrollTo(0, document.body.scrollHeight);
      } else {
        if (this.$refs.signUpBox) {
          this.$refs.signUpBox.scrollTo(0, this.$refs.signUpBox.scrollHeight);
        }
      }
    },
    setStyles() {
      if (this.$vuetify.breakpoint.xsOnly) {
        document.body.style.height = undefined;
        document.body.style.width = undefined;
        document.body.style.overflow = "auto";
        this.$refs.signUpBox.style.height = "auto";
      } else {
        document.body.style.width = "calc(100vw - (100vw - 100%))";
        document.body.style.height = "calc(100vh - (100vh - 100%))";
        document.body.style.overflowX = "hidden";
        document.body.style.overflowY = "hidden";
        this.$refs.signUpBox.style.height = "100vh";
      }
    },
    onResize() {
      this.setStyles();
    },
  },
  mounted() {
    const resizeObserver = new ResizeObserver(() => {
      this.scrollToBottom();
    });
    resizeObserver.observe(this.$refs.signUpInnerBox);
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style>
html,
body {
  overflow-y: unset;
}
</style>
