var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-row",
        { staticClass: "mx-0 px-0" },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex py-10 px-16",
              class: {
                "justify-end": _vm.$vuetify.breakpoint.smAndUp,
                "justify-center": _vm.$vuetify.breakpoint.xsOnly
              },
              staticStyle: { "background-color": "#00a9f4" },
              attrs: { cols: "12", sm: "6" }
            },
            [
              _c("div", { staticStyle: { "max-width": "400px" } }, [
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-center",
                    class: { "my-8": _vm.$vuetify.breakpoint.xsOnly }
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        src: require("@/assets/UtiliSyncFieldReportsWhite.svg")
                      }
                    })
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "white--text",
                    staticStyle: { "margin-top": "16px", "font-size": "24px" }
                  },
                  [_vm._v(" Create field reports... from the field! ")]
                ),
                _c("p", { staticClass: "white--text" }, [
                  _vm._v(
                    " Stop wasting precious office time filling out Word documents, downloading photos and creating PDF reports. You can do all that and more with UtiliSync Docs. "
                  )
                ]),
                _c("p", { staticClass: "white--text" }, [
                  _vm._v(
                    " So what are you waiting for? Sign up for your free UtiliSync account today. "
                  )
                ])
              ])
            ]
          ),
          _c(
            "v-col",
            {
              ref: "signUpBox",
              staticClass: "d-flex",
              class: {
                "px-16": _vm.$vuetify.breakpoint.mdAndUp,
                "px-8": _vm.$vuetify.breakpoint.smAndDown
              },
              style: {
                height: "100vh",
                "overflow-y": "auto"
              },
              attrs: { cols: "12", sm: "6" }
            },
            [
              _c(
                "v-card",
                { staticClass: "elevation-0", attrs: { "max-width": "400px" } },
                [
                  _c("v-card-text", [
                    _c(
                      "div",
                      { ref: "signUpInnerBox" },
                      [
                        _c("SignUpStep1", {
                          on: {
                            "step-1-submit": _vm.onStep1Submit,
                            "step-1-finished": function($event) {
                              _vm.step1Finished = true
                            }
                          }
                        }),
                        _vm.step1Finished
                          ? _c("SignUpStep2", {
                              attrs: { enteredUser: _vm.user },
                              on: { "step-2-finished": _vm.onStep2Finished }
                            })
                          : _vm._e(),
                        _vm.step1Finished && _vm.step2Finished
                          ? _c("SignUpStep3", {
                              attrs: { userGroup: _vm.userGroup },
                              on: { "step-3-finished": _vm.onStep3Finished }
                            })
                          : _vm._e(),
                        _vm.step1Finished &&
                        _vm.step2Finished &&
                        _vm.step3Finished
                          ? _c("SignUpStep4", {
                              on: { "step-4-finished": _vm.onStep4Finished }
                            })
                          : _vm._e(),
                        _vm.step1Finished &&
                        _vm.step2Finished &&
                        _vm.step3Finished &&
                        _vm.step4Finished
                          ? _c("SignUpStep5", {
                              on: { "step-5-finished": _vm.onStep5Finished }
                            })
                          : _vm._e(),
                        _c(
                          "v-expand-transition",
                          [
                            _c(
                              "v-alert",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.step5Finished,
                                    expression: "step5Finished"
                                  }
                                ],
                                staticClass:
                                  "rounded-tl-lg rounded-tr-lg rounded-br-lg",
                                attrs: {
                                  color: "#3F51B5",
                                  dark: "",
                                  width: "85%"
                                }
                              },
                              [
                                _vm._v(
                                  " Perfect! I have everything I need to create your account. Just give me a minute to get everything created. "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-expand-transition",
                          [
                            _c(
                              "v-alert",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.step5Finished,
                                    expression: "step5Finished"
                                  }
                                ],
                                staticClass:
                                  "rounded-tl-lg rounded-tr-lg rounded-br-lg",
                                attrs: {
                                  color: "#3F51B5",
                                  dark: "",
                                  width: "85%"
                                }
                              },
                              [
                                _vm._v(
                                  " All set! Your new account is created and is waiting for you. I just sent an email to " +
                                    _vm._s(_vm.user.email) +
                                    " with a link to create your password. Watch for that to come in the next few minutes. Thanks for coming by today. It’s been a pleasure working with you! "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-expand-transition",
                          [
                            _c(
                              "v-btn",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.step5Finished,
                                    expression: "step5Finished"
                                  }
                                ],
                                attrs: { text: "", to: "/login" }
                              },
                              [_vm._v("Sign In")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }