<template>
  <div>
    <v-btn fab color="#3F51B5" class="elevation-2 mb-3" small>
      <img
        src="@/assets/UtiliBotIconWhiteV2.svg"
        style="width: 35px; height: 35px"
      />
    </v-btn>

    <v-expand-transition>
      <v-alert
        color="#3F51B5"
        dark
        class="rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg"
        width="85%"
        v-show="showWelcomeMsg"
      >
        Hi! I’m the Create Account UtiliBot and I will guide you through the
        process of creating a UtiliSync account. Let’s begin.
      </v-alert>
    </v-expand-transition>

    <v-expand-transition>
      <v-alert
        color="#3F51B5"
        dark
        class="rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg"
        width="85%"
        v-show="showFirstNameMsg"
      >
        What is your first name?
      </v-alert>
    </v-expand-transition>

    <div v-if="showFirstNameMsg">
      <div v-if="!submittedFirstName" class="my-5">
        <validation-observer>
          <form @submit.prevent="saveFirstName">
            <validation-provider rules="required">
              <v-text-field
                outlined
                color="#E5E5E5"
                v-model.trim="user.firstName"
                hide-details="auto"
                name="firstName"
                single-line
                background-color="white"
                full-width
                ref="firstNameInput"
              >
                <template v-slot:append>
                  <div class="mt-n2">
                    <v-btn
                      icon
                      type="submit"
                      color="#3F51B5"
                      :disabled="!user.firstName"
                    >
                      <v-icon>
                        {{ mdiSend }}
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-text-field>
            </validation-provider>
          </form>
        </validation-observer>
      </div>
      <div v-else class="d-flex justify-end my-5">
        <v-list
          outlined
          rounded
          width="fit-content"
          dense
          class="rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-0"
        >
          <v-list-item>
            {{ user.firstName }}
          </v-list-item>
        </v-list>
      </div>
    </div>

    <v-expand-transition>
      <v-alert
        color="#3F51B5"
        dark
        class="rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg"
        width="85%"
        v-show="submittedFirstName"
      >
        Hi {{ user.firstName }}. Nice to meet you!
      </v-alert>
    </v-expand-transition>

    <v-expand-transition>
      <v-alert
        color="#3F51B5"
        dark
        class="rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg"
        width="85%"
        v-show="showLastNameMsg"
      >
        What is your last name?
      </v-alert>
    </v-expand-transition>

    <div v-if="showLastNameMsg" class="my-5">
      <div v-if="!submittedLastName">
        <validation-observer>
          <form @submit.prevent="saveLastName">
            <validation-provider rules="required">
              <v-text-field
                outlined
                color="#E5E5E5"
                v-model.trim="user.lastName"
                hide-details="auto"
                name="lastName"
                single-line
                background-color="white"
                full-width
                ref="lastNameInput"
              >
                <template v-slot:append>
                  <div class="mt-n2">
                    <v-btn
                      icon
                      type="submit"
                      color="#3F51B5"
                      :disabled="!user.lastName"
                    >
                      <v-icon>
                        {{ mdiSend }}
                      </v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-text-field>
            </validation-provider>
          </form>
        </validation-observer>
      </div>
      <div v-else class="d-flex justify-end">
        <v-list
          outlined
          rounded
          width="fit-content"
          dense
          class="rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-0"
        >
          <v-list-item>
            {{ user.lastName }}
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiSend } from "@mdi/js";
import sleep from "@/mixins/sleep";

export default {
  name: "SignUpStep1",
  data() {
    return {
      mdiSend,
      user: {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
      },
      showWelcomeMsg: false,
      showFirstNameMsg: false,
      showLastNameMsg: false,
      submittedFirstName: false,
      submittedLastName: false,
    };
  },
  async beforeMount() {
    await sleep(1000);
    this.showWelcomeMsg = true;
    await sleep(1000);
    this.showFirstNameMsg = true;
    await this.$nextTick();
    if (this.$vuetify.breakpoint.smAndUp) {
      this.$refs.firstNameInput.$refs.input.focus();
    }
  },
  methods: {
    async saveFirstName() {
      this.submittedFirstName = true;
      await sleep(1000);
      this.showLastNameMsg = true;
      this.$emit("step-1-submit", this.user);
      if (this.$vuetify.breakpoint.smAndUp) {
        await this.$nextTick();
        this.$refs.lastNameInput.$refs.input.focus();
      }
    },
    saveLastName() {
      this.submittedLastName = true;
      this.$emit("step-1-submit", this.user);
      this.$emit("step-1-finished");
    },
  },
};
</script>
