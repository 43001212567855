var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEmailMsg,
                  expression: "showEmailMsg"
                }
              ],
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg",
              attrs: { color: "#3F51B5", dark: "", width: "85%" }
            },
            [
              _vm._v(
                " It looks like you already have an account on UtiliSync811. Would you like me to send you an email with your username and link to reset your password? "
              )
            ]
          )
        ],
        1
      ),
      _vm.showEmailMsg
        ? _c(
            "div",
            { staticClass: "d-flex justify-end mb-5" },
            [
              !_vm.emailOption
                ? _c(
                    "v-list",
                    {
                      staticClass: "py-0",
                      style: {
                        "border-radius": "1%",
                        border: "1px solid #00A9F4"
                      },
                      attrs: { dense: "", width: "fit-content" }
                    },
                    _vm._l(_vm.EMAIL_USER_OPTIONS, function(e, i) {
                      return _c(
                        "v-list-item",
                        {
                          key: e.value,
                          staticClass: "my-0 py-0",
                          style: {
                            "border-bottom":
                              i !== _vm.EMAIL_USER_OPTIONS.length - 1
                                ? "1px solid #00A9F4"
                                : "",
                            "background-color":
                              _vm.emailOptionValue === e.value
                                ? "#00A9F4"
                                : "white"
                          },
                          attrs: { color: "#3F51B5" },
                          on: {
                            click: function($event) {
                              return _vm.onEmailUserOptionClick(e.value)
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              style: {
                                color:
                                  _vm.emailOptionValue === e.value
                                    ? "white"
                                    : "#00A9F4"
                              }
                            },
                            [_vm._v(" " + _vm._s(e.label) + " ")]
                          )
                        ]
                      )
                    }),
                    1
                  )
                : _c(
                    "v-list",
                    {
                      attrs: { outlined: "", rounded: "", width: "fit-content" }
                    },
                    [
                      _c("v-list-item", [
                        _vm._v(" " + _vm._s(_vm.emailOption) + " ")
                      ])
                    ],
                    1
                  )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.emailOptionValue ===
                    _vm.EMAIL_OPTIONS.ENTER_EMAIL_AGAIN,
                  expression:
                    "emailOptionValue === EMAIL_OPTIONS.ENTER_EMAIL_AGAIN"
                }
              ],
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg",
              attrs: { color: "#3F51B5", dark: "", width: "85%" }
            },
            [_vm._v(" What is your work email? ")]
          )
        ],
        1
      ),
      _vm.emailOptionValue === _vm.EMAIL_OPTIONS.ENTER_EMAIL_AGAIN
        ? _c("div", [
            !_vm.emailSubmitted
              ? _c(
                  "div",
                  { staticClass: "my-5" },
                  [
                    _c("validation-observer", { ref: "emailForm" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.submitEmail.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c(
                            "validation-provider",
                            { attrs: { rules: "required" } },
                            [
                              _c("v-text-field", {
                                ref: "emailInput",
                                attrs: {
                                  outlined: "",
                                  color: "#E5E5E5",
                                  "hide-details": "auto",
                                  name: "email",
                                  "single-line": "",
                                  "background-color": "white",
                                  "full-width": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "mt-n2" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    type: "submit",
                                                    color: "#3F51B5",
                                                    disabled: !_vm.user.email
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.mdiSend) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3570034900
                                ),
                                model: {
                                  value: _vm.user.email,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.user,
                                      "email",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "user.email"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "d-flex justify-end my-5" },
                  [
                    _c(
                      "v-list",
                      {
                        staticClass:
                          "rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-0",
                        attrs: {
                          outlined: "",
                          rounded: "",
                          width: "fit-content",
                          dense: ""
                        }
                      },
                      [
                        _c("v-list-item", [
                          _vm._v(" " + _vm._s(_vm.user.email) + " ")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }