var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showAlreadyExistMsg,
                  expression: "showAlreadyExistMsg"
                }
              ],
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg",
              attrs: { color: "#3F51B5", dark: "", width: "85%" }
            },
            [
              _vm._v(
                " An account for " +
                  _vm._s(_vm.prevEnteredGroupName) +
                  " already exists. Are you trying to create a new user for that account? "
              )
            ]
          )
        ],
        1
      ),
      _vm.showAlreadyExistMsg
        ? _c(
            "div",
            { staticClass: "d-flex justify-end mb-5" },
            [
              !_vm.userGroupOptionValue
                ? _c(
                    "v-list",
                    {
                      staticClass: "py-0",
                      style: {
                        "border-radius": "1%",
                        border: "1px solid #00A9F4"
                      },
                      attrs: { width: "fit-content", dense: "" }
                    },
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "my-0 py-0",
                          style: {
                            "border-bottom": "1px solid #00A9F4",
                            "background-color":
                              _vm.userGroupOptionValue ===
                              _vm.USER_GROUP_OPTIONS.CREATE_NEW_USER
                                ? "#00A9F4"
                                : "white"
                          },
                          attrs: { color: "#3F51B5" },
                          on: {
                            click: function($event) {
                              _vm.userGroupOptionValue =
                                _vm.USER_GROUP_OPTIONS.CREATE_NEW_USER
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              style: {
                                color:
                                  _vm.userGroupOptionValue ===
                                  _vm.USER_GROUP_OPTIONS.CREATE_NEW_USER
                                    ? "white"
                                    : "#00A9F4"
                              }
                            },
                            [
                              _vm._v(
                                " I want to create a new user for " +
                                  _vm._s(_vm.prevEnteredGroupName) +
                                  " "
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "my-0 py-0",
                          style: {
                            "border-bottom": "1px solid #00A9F4",
                            "background-color":
                              _vm.userGroupOptionValue ===
                              _vm.USER_GROUP_OPTIONS.CONTACT_UTILISYNC_SUPPORT
                                ? "#00A9F4"
                                : "white"
                          },
                          attrs: { color: "#3F51B5" },
                          on: {
                            click: function($event) {
                              _vm.userGroupOptionValue =
                                _vm.USER_GROUP_OPTIONS.CONTACT_UTILISYNC_SUPPORT
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              style: {
                                color:
                                  _vm.userGroupOptionValue ===
                                  _vm.USER_GROUP_OPTIONS
                                    .CONTACT_UTILISYNC_SUPPORT
                                    ? "white"
                                    : "#00A9F4"
                              }
                            },
                            [
                              _vm._v(
                                " I want to contact UtiliSync811 Support. "
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "my-0 py-0",
                          style: {
                            "background-color":
                              _vm.userGroupOptionValue ===
                              _vm.USER_GROUP_OPTIONS.ENTER_ORG_NAME_AGAIN
                                ? "#00A9F4"
                                : "white"
                          },
                          attrs: { color: "#3F51B5" },
                          on: {
                            click: function($event) {
                              _vm.userGroupOptionValue =
                                _vm.USER_GROUP_OPTIONS.ENTER_ORG_NAME_AGAIN
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              style: {
                                color:
                                  _vm.userGroupOptionValue ===
                                  _vm.USER_GROUP_OPTIONS.ENTER_ORG_NAME_AGAIN
                                    ? "white"
                                    : "#00A9F4"
                              }
                            },
                            [
                              _vm._v(
                                " I would like to enter my organization name again. "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _c(
                    "v-list",
                    {
                      staticClass:
                        "rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-0",
                      attrs: { outlined: "", rounded: "", width: "fit-content" }
                    },
                    [
                      _c("v-list-item", [
                        _vm._v(" " + _vm._s(_vm.userGroupOption) + " ")
                      ])
                    ],
                    1
                  )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.userGroupOptionValue ===
                    _vm.USER_GROUP_OPTIONS.CREATE_NEW_USER,
                  expression:
                    "userGroupOptionValue === USER_GROUP_OPTIONS.CREATE_NEW_USER"
                }
              ],
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg",
              attrs: { color: "#3F51B5", dark: "", width: "85%" }
            },
            [
              _vm._v(
                " Great. I’m only programmed to create new accounts. Please contact your UtiliSync811 admin or contact UtiliSync support by "
              ),
              _c(
                "a",
                {
                  staticClass: "white--text",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      return _vm.showChat()
                    }
                  }
                },
                [_vm._v("clicking here")]
              ),
              _vm._v(" and they can help you with that. ")
            ]
          )
        ],
        1
      ),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.userGroupOptionValue ===
                    _vm.USER_GROUP_OPTIONS.CONTACT_UTILISYNC_SUPPORT,
                  expression:
                    "\n        userGroupOptionValue === USER_GROUP_OPTIONS.CONTACT_UTILISYNC_SUPPORT\n      "
                }
              ],
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg",
              attrs: { color: "#3F51B5", dark: "", width: "85%" }
            },
            [
              _vm._v(" Great. "),
              _c(
                "a",
                {
                  staticClass: "white--text",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      return _vm.showChat()
                    }
                  }
                },
                [_vm._v("Click here")]
              ),
              _vm._v(
                " to send an email to support. They typically respond within a few hours. "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-expand-transition",
        [
          _c(
            "v-alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.userGroupOptionValue ===
                    _vm.USER_GROUP_OPTIONS.ENTER_ORG_NAME_AGAIN,
                  expression:
                    "\n        userGroupOptionValue === USER_GROUP_OPTIONS.ENTER_ORG_NAME_AGAIN\n      "
                }
              ],
              staticClass:
                "rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg",
              attrs: { color: "#3F51B5", dark: "", width: "85%" }
            },
            [
              _vm._v(
                " What is the name of the organization that will be using this account? "
              )
            ]
          )
        ],
        1
      ),
      _vm.userGroupOptionValue === _vm.USER_GROUP_OPTIONS.ENTER_ORG_NAME_AGAIN
        ? _c("div", [
            !_vm.userGroupSubmitted
              ? _c(
                  "div",
                  { staticClass: "my-5" },
                  [
                    _c("validation-observer", [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.submitUserGroupName.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "validation-provider",
                            [
                              _c("v-text-field", {
                                ref: "userGroupNameInput",
                                attrs: {
                                  outlined: "",
                                  color: "#E5E5E5",
                                  "hide-details": "auto",
                                  name: "name",
                                  "single-line": "",
                                  "background-color": "white",
                                  "full-width": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "mt-n2" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    type: "submit",
                                                    color: "#3F51B5",
                                                    disabled: !_vm.userGroup
                                                      .name
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.mdiSend) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3031676160
                                ),
                                model: {
                                  value: _vm.userGroup.name,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.userGroup,
                                      "name",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "userGroup.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "d-flex justify-end my-5" },
                  [
                    _c(
                      "v-list",
                      {
                        staticClass:
                          "rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-0",
                        attrs: {
                          outlined: "",
                          rounded: "",
                          width: "fit-content",
                          dense: ""
                        }
                      },
                      [
                        _c("v-list-item", [
                          _vm._v(" " + _vm._s(_vm.userGroup.name) + " ")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }