<template>
  <div>
    <v-expand-transition>
      <v-alert
        color="#3F51B5"
        dark
        class="rounded-tl-lg rounded-tr-lg rounded-bl-0 rounded-br-lg"
        width="85%"
        v-show="showTimeZoneMsg"
      >
        Last question, which time zone are you in?
      </v-alert>
    </v-expand-transition>

    <div v-if="showTimeZoneMsg">
      <div v-if="!timeZoneSubmitted" class="d-flex justify-end">
        <form>
          <v-list
            :style="{
              width: 'fit-content',
              'border-radius': '1%',
              border: '1px solid #00A9F4',
            }"
            class="py-0"
            dense
          >
            <v-list-item
              v-for="(c, i) of TIME_ZONE_CHOICES"
              :key="c.label"
              :style="{
                'border-bottom':
                  i !== TIME_ZONE_CHOICES.length - 1 ? '1px solid #00A9F4' : '',
                'background-color': 'white',
              }"
              class="my-0 py-0"
              @click="
                user.timeZone = c.label;
                timeZoneSubmitted = true;
                submitTimezone();
              "
            >
              <span :style="{ color: '#00A9F4' }">
                {{ c.label }}
              </span>
            </v-list-item>
          </v-list>
        </form>
      </div>
      <div v-else class="d-flex justify-end my-5">
        <v-list
          outlined
          rounded
          width="fit-content"
          dense
          class="rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-0"
        >
          <v-list-item>
            {{ user.timeZone }}
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiSend } from "@mdi/js";
import sleep from "@/mixins/sleep";
const TIME_ZONE_CHOICES = [
  { value: "US/Pacific", label: "US/Pacific" },
  { value: "US/Mountain", label: "US/Mountain" },
  { value: "US/Central", label: "US/Central" },
  { value: "US/Eastern", label: "US/Eastern" },
];

export default {
  name: "SignUpStep5",
  data() {
    return {
      TIME_ZONE_CHOICES,
      mdiSend,
      user: {
        username: "",
        email: "",
        timeZone: "",
      },
      timeZoneSubmitted: false,
      showTimeZoneMsg: false,
    };
  },
  async mounted() {
    await sleep(1000);
    this.showTimeZoneMsg = true;
  },
  methods: {
    submitTimezone() {
      this.timeZoneSubmitted = true;
      this.$emit("step-5-finished", this.user);
    },
  },
};
</script>
